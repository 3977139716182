    /* LoadingProgress.css */
    .progress-container {
        width: 30%;
        background-color: #D6A216;
        border-radius: 10px;
        margin: 10px 0;
    }

    .progress-bar {
        height: 20px;
        width: 0;
        background-color: #354E37;
        text-align: center;
        font-size: small;
        color: white;
        border-radius: 5px;
        transition: width 0.3s;
    }