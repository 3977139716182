.texture-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0); /* شفافیت کامل */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    pointer-events: none; /* در صورت نیاز برای عدم تعامل با پنل */
  }
  
  .texture-row {
    display: flex;
    overflow-x: auto;
    pointer-events: all; /* برای تعامل با تصاویر در پنل */

  }
  
  .texture-item {
    margin: 0 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%; /* دایره بودن تصویر */
    overflow: hidden;
    border: 2px solid #ccc;
  }
  
  .texture-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* برای حفظ نسبت ابعادی */
  }
  