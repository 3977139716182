/* Annotation.css */
.annotation-box {
    position: absolute;
    background-color: #354E37;
    border-radius: 8px;
    padding: 10px;
    max-width: 300px;
    color: white;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10; 
    pointer-events: auto; 
}

.annotation-box img {
    width: 100%; 
    max-width: 100px; 
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.annotation-box a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
}
.annotation-box p {
    margin: 10px 0; /* Margin around paragraphs */
    font-size: 14px; /* Font size for paragraph text */
    line-height: 1.4; /* Line height for better readability */
}
.annotation-box a {
    color: #ffffff; /* White link color */
    text-decoration: none; /* Remove underline */
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    transition: color 0.3s ease; /* Smooth transition on hover */
}

.annotation-box a:hover {
    color: #add8e6; /* Light blue on hover */
    text-decoration: underline; /* Underline on hover */
}
