.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  h5 {
    margin-top: 0;
    color: black;
  }
  
  label {
    display: block;
    margin-top: 10px;
    color: black;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button, button {
    background-color: #354E37;
    color: white;
    font-size: small;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
  }
  
  .button:hover, button:hover {
    background-color: #648167;
  }
  
  input[type="file"] {
    display: none;
  }

  